import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';

export interface CurrentWxStore {
  deviceId: number; // 当前选择的设备
  wxId: number; // 当前选择的微信id
  wxStrId: string; // 当前选择的字符串微信id
  currentDeviceInfo: string; //当前选择的设备信息
  friendId: number; // 当前选择的好友id
  friendStrId: string; // 当前选择的好友字符串id
  currentFriInfo: string; // 当前选择的好友信息
  isChatRoom: boolean; // 是否是群聊
}

@Module({dynamic: true, store, name: 'wechat'})
class Wechat extends VuexModule implements CurrentWxStore {
  @Mutation
  private CHANGE_DEVICE_ID(deviceId: number) {
    this.deviceId = deviceId;
  }
 
  @Action({ commit: 'CHANGE_DEVICE_ID' })
  public changeDeviceId(deviceId: string) {
    return deviceId;
  }


  @Action({ commit: 'CHANGE_WXID' })
  public changeWxId(wxId: string) {
    return wxId;
  }

  @Mutation
  private CHANGE_WXID(wxId: number) {
    this.wxId = wxId;
  }

  @Action({ commit: 'CHANGE_WXSTRID' })
  public changeWxStrId(wxStrId: string) {
    return wxStrId;
  }

  @Mutation
  private CHANGE_WXSTRID(wxStrId: string) {
    this.wxStrId = wxStrId;
  }

  @Action({ commit: 'CHANGE_FRIEND_ID' })
  public changeFriendId(friendId: number) {
    return friendId;
  }

  @Mutation
  private CHANGE_FRIEND_ID(friendId: number) {
    this.friendId = friendId;
  }

  @Action({ commit: 'CHANGE_FRIEND_STR_ID' })
  public changeFriendStrId(friendStrId: string) {
    return friendStrId;
  }

  @Mutation
  private CHANGE_FRIEND_STR_ID(friendStrId: string) {
    this.friendStrId = friendStrId;
  }

  @Action({ commit: 'CHANGE_FRIEND_INFO'})
  public changeFriendInfo(info: any) {
    return info;
  }

  @Mutation
  private CHANGE_FRIEND_INFO(info: any) {
    this.currentFriInfo = info;
  }

  @Action({ commit: 'CHANGE_DEVICE_INFO'})
  public changeDeviceInfo(info: string) {
    return info;
  }

  @Mutation
  private CHANGE_DEVICE_INFO(info: string) {
    this.currentDeviceInfo = info;
  }

  @Action({commit: 'CHANGE_IS_CHATROOM'})
  public changeIsChatRoom(ischatroom: boolean) {
    return ischatroom;
  }

  @Mutation
  private CHANGE_IS_CHATROOM(ischatroom: boolean) {
    this.isChatRoom = ischatroom;
  }

  public deviceId: number = 0
  public wxId: number = 0
  public wxStrId: string = ''
  public currentDeviceInfo: string = ''
  public friendId: number = 0
  public friendStrId: string = ''
  public currentFriInfo: string = ''
  public isChatRoom: boolean = false
}

export const WechatModule = getModule(Wechat);