














import {
  api
} from "@/api";
import {
  WechatModule
} from "@/store/modules/wechat";
import {
  onScroll
} from "@/utils/func";
import {
  Component,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'new-friend',
})
export default class NewFriend extends Vue {
  private list: any = []
  private pageSize: number = 20
  private pageNo: number = 1
  private hasNextPage: boolean = true
  private isRequest = false

  private get wxId(): number {
    return WechatModule.wxId
  }

  private mounted() {
    this.getList()
    this.isRequest = false
  }

  /**
   * @func 获取新好友列表
   */
  private async getList() {
    if (this.isRequest) return
    if (!this.hasNextPage) return
    this.isRequest = true
    try {
      const res: any = await api.getNewFriendList({
        wechat_id: this.wxId,
        offset: this.pageNo,
        num: this.pageSize
      });
      !res.list || res.list.length < this.pageSize ?
        this.hasNextPage = false :
        this.pageNo++;
      if (res.list) {
        res.list.map((val: any) => {
          this.list.push(val)
        })
      }
      this.isRequest = false
    } catch (err) {
      this.isRequest = false
      console.log(err)
    }
  }

  /**
   * @func 监听滚动
   */
  private listenScroll(e: any) {
    onScroll({
      e: e,
      scrollUp: () => {
        if (this.list.length > 0) {
          this.getList();
        }
      }
    })
  }

  /**
   * @func 接受好友邀请
   */
  private async acceptNewFriend(row: any, index: number) {
    const data: any = {
      robot: this.wxId,
      task: {
        wechat_id: this.wxId,
        task_friends: {
          task_type: 9,
          pass_verify: {
            wxid: row.username
          }
        },
      },
    }
    
    try {
      const res: any = await api.friendTask(data)
      this.list[index].status = 1;
      const item = this.list[index];
      item.status = 1;
      this.$set(this.list, index, item);
    } catch(err) {
      console.log(err)
    }
  }
}
