export const getTime = (time: number) => {
  const nowTime: number = parseInt(new Date().getTime() / 1000 + '')

  if (parseTime(time, '{y}/{m}/{d}') === parseTime(nowTime, '{y}/{m}/{d}')) {
    return parseTime(time, '{h}:{i}')
  } else if (parseTime(time, '{y}/{m}/{d}') === parseTime(nowTime - 86400, '{y}/{m}/{d}')) {
    return '昨日'
  } else if ((nowTime - 86400 > time) && (time > nowTime - 86400 * 7)) {
    return '星期' + parseTime(time, '{a}')
  } else {
    return parseTime(time, '{y}/{m}/{d}')
  }
}

// 时间转换
export function parseTime(time: number | object, cFormat?: string) {
  if (arguments.length === 0) {
    return null;
  }
  const format: string = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date: Date = new Date();
  if (typeof time === 'object') {
    date = time as Date;
  } else {
    if (String(time).length === 10) {
      // tslint:disable-next-line: radix
      time = parseInt(String(time)) * 1000;
      date = new Date(time);
    }
  }
  const formatObj: any = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };

  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return timeStr;
}

// 判断设备是否为ios
export function isIos(): boolean {
  const u = navigator.userAgent;
  const isios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  return isios
}

interface listenObj {
  e: any;
  scrollUp?: any;
  scrollDown?: any;
  offset?: number;
}

export function onScroll(obj: listenObj) {
  const scrollContainer: any = obj.e.srcElement;
  if (obj.scrollUp) {
    if (scrollContainer.offsetHeight + scrollContainer.scrollTop + 1 >
      scrollContainer.scrollHeight) {
      obj.scrollUp()
    }
  }
  if (obj.scrollDown) {
    if ((scrollContainer.offsetHeight - scrollContainer.scrollTop) > (scrollContainer.scrollHeight - (obj.offset || 10))) {
      obj.scrollDown()
    }
  }
}

// http替换为https
export function replaceToHttps(url: string) {
  const reg: any = new RegExp('http://', 'g');
  return url.replace(reg, 'https://');
}

// map 删除后长度不变需要删除
export function clearNullArr(arr: any) {
  for (var i = 0, len = arr.length; i < len; i++) {
    if (!arr[i] || arr[i] == '') {
      arr.splice(i, 1);
      len--;
      i--;
    }
  }
  return arr;
}

// 复制内容
export function copyText(text: string, callback: any) {
  let url = text;
  let oInput: any = document.createElement('input');
  oInput.value = url;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象;
  document.execCommand("Copy"); // 执行浏览器复制命令
  callback()
  oInput.remove()
}

// 判断当前设备是android 还是ios
export function appSource() {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isiOS) {
    return "ios";
  } else {
    return "andriod";
  }
}